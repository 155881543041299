import { call, put, takeEvery } from 'redux-saga/effects';
import {
	ActionsBobaList,
	failureBobaList,
	loadBobaListSuccess,
} from './actions';
import { loadLoginPopup } from '@/Store/LoginPopup/lib/actions';

function* loadDataBobaList(params = {}) {
	try {
		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getListBoba${params.payload.is_winner ? '?is_winner=true' : ''}`;
		const headers = { ...params.payload.headers };
		const method = 'GET';
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const data = yield response.json();

		if (data?.meta?.code === 200) {
			yield put(loadBobaListSuccess(data));
		} else {
			if (data?.meta?.code === 498) {
				yield put(loadLoginPopup());
			}

			yield put(failureBobaList(data?.meta));
		}
	} catch (err) {
		yield put(failureBobaList('Error fetch boba list ', err));
	}
}

export default function* rootSaga() {
	yield takeEvery(ActionsBobaList.GET_BOBA_LIST, loadDataBobaList);
}
