import { HYDRATE } from 'next-redux-wrapper';
import { ActionsCurrentBoba } from './actions';

const initialState = { data: {}, status: 'fetch_init', message: '' };

function storeCurrentBoba(state = initialState, action) {
	switch (action.type) {
		case HYDRATE:
			return { ...state, ...action.payload.storeCurrentBoba };
		case ActionsCurrentBoba.LOAD_GET_CURRENT_BOBA:
			return { ...state, data: initialState.data, status: 'fetch_loading' };
		case ActionsCurrentBoba.RES_GET_CURRENT_BOBA:
			return { ...state, data: action.data, status: 'fetch_result' };
		case ActionsCurrentBoba.ERR_GET_CURRENT_BOBA:
			return {
				...state,
				status: 'fetch_error',
				message: 'Error...',
			};
		default:
			return { ...state };
	}
}

export default storeCurrentBoba;
