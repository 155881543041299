export const ActionsSearchBrands = {
	GET_SEARCH_BRANDS: 'get_search_brands',
	LOAD_GET_SEARCH_BRANDS: 'load_get_search_brands',
	RES_GET_SEARCH_BRANDS: 'res_get_search_brands',
	ERR_GET_SEARCH_BRANDS: 'err_get_search_brands',
	HYDRATE: 'HYDRATE',
};

export const API_SEARCH_BRANDS = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/searchBrands`;

export function failureSearchBrands(err) {
	return {
		type: ActionsSearchBrands.ERR_GET_SEARCH_BRANDS,
		err,
	};
}

export function loadSearchBrands(params = {}) {
	return {
		type: ActionsSearchBrands.GET_SEARCH_BRANDS,
		...params,
	};
}

export function loadGetSearchBrands(params = {}) {
	return {
		type: ActionsSearchBrands.LOAD_GET_SEARCH_BRANDS,
		...params,
	};
}

export function loadSearchBrandsSuccess(data) {
	return {
		type: ActionsSearchBrands.RES_GET_SEARCH_BRANDS,
		data,
	};
}
