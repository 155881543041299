import { HYDRATE } from 'next-redux-wrapper';
import { ActionLoginPopup } from './actions';

const initialState = {
	isShowLoginPopup: false,
	status: 'init',
	message: '',
	error: null,
};

function storeLoginPopup(state = initialState, action) {
	switch (action.type) {
		case HYDRATE:
			return { ...state, ...action.payload.storeLoginPopup };
		case ActionLoginPopup.LOAD_GET_LOGIN_POPUP:
			return { ...state, status: 'loading' };
		case ActionLoginPopup.RES_GET_LOGIN_POPUP:
			return { ...state, isShowLoginPopup: action.data, status: 'result' };
		case ActionLoginPopup.ERR_GET_LOGIN_POPUP:
			return {
				...state,
				isShowLoginPopup: false,
				status: 'error',
				error: action.error,
			};
		default:
			return { ...state };
	}
}

export default storeLoginPopup;
