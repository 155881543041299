import { call, put, takeEvery } from 'redux-saga/effects';
import {
	ActionsCompleting,
	API_COMPLETING,
	failureCompleting,
	loadCompletingSuccess,
} from './actions';
import { loadLoginPopup } from '@/Store/LoginPopup/lib/actions';

function* loadDataCompleting(params = {}) {
	try {
		const endpoint = API_COMPLETING;
		const headers = {
			...params.payload.headers,
			authorization: params.payload.Authorization,
		};
		const method = 'GET';
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const data = yield response.json();

		if (data?.meta?.code === 200) {
			yield put(loadCompletingSuccess(data));
		} else {
			if (data?.meta?.code === 498) {
				yield put(loadLoginPopup());
			}

			yield put(failureCompleting(data?.meta));
		}
	} catch (err) {
		yield put(failureCompleting(err));
	}
}

export default function* rootSaga() {
	yield takeEvery(ActionsCompleting.GET_COMPLETING, loadDataCompleting);
}
