import { call, put, takeEvery } from 'redux-saga/effects';
import {
	ActionsSearchBrands,
	API_SEARCH_BRANDS,
	failureSearchBrands,
	loadGetSearchBrands,
	loadSearchBrandsSuccess,
} from './actions';
import { loadLoginPopup } from '@/Store/LoginPopup/lib/actions';

function* loadDataSearchBrands(params = {}) {
	yield put(loadGetSearchBrands());
	try {
		const endpoint = `${API_SEARCH_BRANDS}?search=${params.payload.search}`;
		const headers = { ...params.payload.headers };
		const method = 'GET';
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const data = yield response.json();
		if (data?.meta?.code === 200) {
			const brandsData = [];
			data.data.map((item) => {
				brandsData.push({
					id: item.id,
					name: item.name,
					slug: item.slug,
					date: item.created_at,
					image: item.image,
					price: item.price,
					total_products: item.total_product,
					total_reviews: item.total_review,
					rating: item.rating,
					origin: item.origin,
					is_top: item.is_top,
					sort_frontend: item.sort_frontend,
				});

				return brandsData;
			});

			yield put(loadSearchBrandsSuccess(brandsData));
		} else {
			if (data?.meta?.code === 498) {
				yield put(loadLoginPopup());
			}

			yield put(failureSearchBrands(data?.meta));
		}
	} catch (err) {
		yield put(failureSearchBrands(err));
	}
}

export default function* rootSaga() {
	yield takeEvery(ActionsSearchBrands.GET_SEARCH_BRANDS, loadDataSearchBrands);
}
