import { call, put, takeEvery } from 'redux-saga/effects';
import {
	ActionsSearchUsers,
	API_SEARCH_USERS,
	failureSearchUsers,
	loadGetSearchUsers,
	loadSearchUsersSuccess,
} from './actions';
import { predefinedSearchUsersModel } from '@/Store/helpers/searchUsers';
import { loadLoginPopup } from '@/Store/LoginPopup/lib/actions';

function* loadDataSearchUsers(params = {}) {
	yield put(loadGetSearchUsers());
	try {
		const endpoint = `${API_SEARCH_USERS}?search=${params.payload.search}`;
		const headers = { ...params.payload.headers };
		const method = 'GET';
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const data = yield response.json();
		if (data?.meta?.code === 200) {
			const dataUsers = predefinedSearchUsersModel(data.data.users);

			yield put(loadSearchUsersSuccess(dataUsers.data));
		} else {
			yield put(failureSearchUsers(data?.meta));
			if (data?.meta?.code === 498) {
				yield put(loadLoginPopup());
			}
		}
	} catch (err) {
		yield put(failureSearchUsers(err));
	}
}

export default function* reduxSaga() {
	yield takeEvery(ActionsSearchUsers.GET_SEARCH_USERS, loadDataSearchUsers);
}
