// Actions TagSlug
export const ActionsArticleTagSlug = {
	GET_ARTICLE_TAG_SLUG: 'get_article_tag_slug',
	LOAD_GET_ARTICLE_TAG_SLUG: 'load_get_article_tag_slug',
	RES_GET_ARTICLE_TAG_SLUG: 'result_get_article_tag_slug',
	ERR_GET_ARTICLE_TAG_SLUG: 'error_get_article_tag_slug',
	HYDRATE: 'HYDRATE',
};

// API ARTICLE
export const API_ARTICLE_TAG_SLUG = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/articleByTagSlug`;

// Function if Failure
export function failureArticleTagSlug(error) {
	return {
		type: ActionsArticleTagSlug.ERR_GET_ARTICLE_TAG_SLUG,
		error,
	};
}

// Function if LoadTagSlug
export function loadArticleTagSlug(params = {}) {
	return {
		type: ActionsArticleTagSlug.GET_ARTICLE_TAG_SLUG,
		...params,
	};
}

// Function if LoadTagSlug
export function loadArticleTagSlugSuccess(data) {
	return {
		type: ActionsArticleTagSlug.RES_GET_ARTICLE_TAG_SLUG,
		data,
	};
}
