export const ActionsBobaList = {
	GET_BOBA_LIST: 'get_boba_list',
	LOAD_GET_BOBA_LIST: 'load_get_boba_list',
	RES_GET_BOBA_LIST: 'res_get_boba_list',
	ERR_GET_BOBA_LIST: 'err_get_boba_list',
	HYDRATE: 'HIDRATE',
};

export function failureBobaList(err) {
	return {
		type: ActionsBobaList.ERR_GET_BOBA_LIST,
		err,
	};
}

export function loadBobaList(params = {}) {
	return {
		type: ActionsBobaList.GET_BOBA_LIST,
		...params,
	};
}

export function loadGetBobaList(params = {}) {
	return {
		type: ActionsBobaList.LOAD_GET_BOBA_LIST,
		...params,
	};
}

export function loadBobaListSuccess(data) {
	return {
		type: ActionsBobaList.RES_GET_BOBA_LIST,
		data,
	};
}
