import { HYDRATE } from 'next-redux-wrapper';
import { ActionsWallet } from './actions';

const initialState = { data: {}, status: 'fetch_init', message: '' };

function storeWallet(state = initialState, action) {
	switch (action.type) {
		case HYDRATE:
			return { ...state, ...action.payload.storeWallet };
		case ActionsWallet.LOAD_GET_WALLET:
			return { ...state, data: action.data, status: 'fetch_loading' };
		case ActionsWallet.RES_GET_WALLET:
			return { ...state, data: action.data, status: 'fetch_result' };
		case ActionsWallet.ERR_GET_WALLET:
			return {
				...state,
				status: 'fetch_error',
				message: 'Error...',
			};
		default:
			return { ...state };
	}
}

export default storeWallet;
