import { call, put, takeEvery } from 'redux-saga/effects';
import {
	ActionsSearchArticles,
	API_SEARCH_ARTICLES,
	failureSearchArticles,
	loadGetSearchArticles,
	loadSearchArticlesSuccess,
} from './actions';
import { loadLoginPopup } from '@/Store/LoginPopup/lib/actions';

function* loadDataSearchArticles(params = {}) {
	yield put(loadGetSearchArticles());
	try {
		const endpoint = `${API_SEARCH_ARTICLES}?search=${params.payload.search}`;
		const headers = { ...params.payload.headers };
		const method = 'GET';
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const data = yield response.json();

		if (data?.meta?.code === 200) {
			const articlesData = [];
			data.data.map((item) =>
				articlesData.push({
					id: item.id,
					date: item.created_at,
					image: item.image,
					url: item.url,
					title: item.title,
					text: item.excerpt,
					category: {
						name: item.category.length > 0 ? item.category[0].name : '',
						slug: item.category_slug,
						slugs: item.category_slugs,
					},
					author: {
						username: item.author.username,
						fullname: item.author.name,
					},
				}),
			);
			yield put(loadSearchArticlesSuccess(articlesData));
		} else {
			if (data?.meta?.code === 498) {
				yield put(loadLoginPopup());
			}

			yield put(failureSearchArticles(data?.meta));
		}
	} catch (err) {
		yield put(failureSearchArticles(err));
	}
}

export default function* rootSaga() {
	yield takeEvery(
		ActionsSearchArticles.GET_SEARCH_ARTICLES,
		loadDataSearchArticles,
	);
}
