export const ActionsWinners = {
	GET_WINNERS: 'get_winners',
	LOAD_GET_WINNERS: 'load_get_winners',
	RES_GET_WINNERS: 'res_get_winners',
	ERR_GET_WINNERS: 'err_get_winners',
	HYDRATE: 'HYDRATE',
};

export const API_WINNERS = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/winners`;

export function failureWinners(err) {
	return {
		type: ActionsWinners.ERR_GET_WINNERS,
		err,
	};
}

export function loadWinners(params = {}) {
	return {
		type: ActionsWinners.GET_WINNERS,
		...params,
	};
}

export function loadGetWinners() {
	return {
		type: ActionsWinners.LOAD_GET_WINNERS,
	};
}

export function loadWinnersSuccess(data) {
	return {
		type: ActionsWinners.RES_GET_WINNERS,
		data,
	};
}
