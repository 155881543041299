import { call, put, takeEvery } from 'redux-saga/effects';
import {
	ActionsArticleTagSlug,
	// API_ARTICLE_TAG_SLUG,
	failureArticleTagSlug,
	loadArticleTagSlugSuccess,
} from './actions';
import { loadLoginPopup } from '@/Store/LoginPopup/lib/actions';

// Function Fetch Article
function* loadDataTagSlug(params = {}) {
	try {
		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/articleByTagSlug`;
		const headers = { ...params.payload.headers };
		const method = 'GET';
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const data = yield response.json();

		if (data?.meta?.code === 200) {
			yield put(loadArticleTagSlugSuccess(data));
		} else {
			if (data?.meta?.code === 498) {
				yield put(loadLoginPopup());
			}

			yield put(failureArticleTagSlug(data?.meta));
		}
	} catch (err) {
		yield put(failureArticleTagSlug(err));
	}
}

export default function* rootSaga() {
	yield takeEvery(ActionsArticleTagSlug.GET_ARTICLE_TAG_SLUG, loadDataTagSlug);
}
