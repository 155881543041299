export const ActionsCategory = {
	GET_CATEGORY: 'get_category',
	LOAD_GET_CATEGORY: 'load_get_category',
	RES_GET_CATEGORY: 'res_get_category',
	ERR_GET_CATEGORY: 'err_get_category',
	HYDRATE: 'HYDRATE',
};

export const API_CATEGORY = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/bobaCategory`;

export function failureCategory(err) {
	return {
		type: ActionsCategory.ERR_GET_CATEGORY,
		err,
	};
}

export function loadCategory(params = {}) {
	return {
		type: ActionsCategory.GET_CATEGORY,
		...params,
	};
}

export function loadGetCategory(params = {}) {
	return {
		type: ActionsCategory.LOAD_GET_CATEGORY,
		...params,
	};
}

export function loadCategorySuccess(data) {
	return {
		type: ActionsCategory.RES_GET_CATEGORY,
		...data,
	};
}
