import { HYDRATE } from 'next-redux-wrapper';
import { ActionsSearchArticles } from './actions';

const initialState = { data: [], status: 'fetch_init', message: '' };

function storeSearchArticles(state = initialState, action) {
	switch (action.type) {
		case HYDRATE:
			return { ...state, ...action.payload.storeSearchArticles };
		case ActionsSearchArticles.LOAD_GET_SEARCH_ARTICLES:
			return { ...state, data: initialState.data, status: 'fetch_loading' };
		case ActionsSearchArticles.RES_GET_SEARCH_ARTICLES:
			return { ...state, data: action.data, status: 'fetch_result' };
		case ActionsSearchArticles.ERR_GET_SEARCH_ARTICLES:
			return {
				...state,
				data: action,
				status: 'fetch_error',
				message: 'Error...',
			};
		default:
			return { ...state };
	}
}

export default storeSearchArticles;
