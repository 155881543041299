export const ActionsPredefined = {
	GET_PREDEFINED: 'get_predefined',
	LOAD_GET_PREDEFINED: 'load_get_predefined',
	RES_GET_PREDEFINED: 'res_get_predefined',
	ERR_GET_PREDEFINED: 'err_get_predefined',
	HYDRATE: 'HYDRATE',
};

export const API_PREDEFINED = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/predefined`;

export function failurePredefined(err) {
	return {
		type: ActionsPredefined.ERR_GET_PREDEFINED,
		err,
	};
}

export function loadPredefined(params = {}) {
	return {
		type: ActionsPredefined.GET_PREDEFINED,
		...params,
	};
}

export function loadGetPredefined(params = {}) {
	return {
		type: ActionsPredefined.LOAD_GET_PREDEFINED,
		...params,
	};
}

export function loadPredefinedSuccess(data) {
	return {
		type: ActionsPredefined.RES_GET_PREDEFINED,
		data,
	};
}
