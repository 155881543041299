import { HYDRATE } from 'next-redux-wrapper';
import { ActionsSearchBrands } from './actions';

const initialState = { data: [], status: 'fetch_init', message: '' };

function storeSearchBrands(state = initialState, action) {
	switch (action.type) {
		case HYDRATE:
			return { ...state, ...action.payload.storeSearchBrands };
		case ActionsSearchBrands.LOAD_GET_SEARCH_BRANDS:
			return { ...state, data: initialState.data, status: 'fetch_loading' };
		case ActionsSearchBrands.RES_GET_SEARCH_BRANDS:
			return { ...state, data: action.data, status: 'fetch_result' };
		case ActionsSearchBrands.ERR_GET_SEARCH_BRANDS:
			return {
				...state,
				data: action,
				status: 'fetch_error',
				message: 'Error...',
			};
		default:
			return { ...state };
	}
}

export default storeSearchBrands;
