import { call, put, takeEvery } from 'redux-saga/effects';
import {
	ActionsMyVote,
	API_MY_VOTE,
	// loadGetMyVote,
	failureMyVote,
	loadMyVoteSuccess,
} from './actions';
import { loadLoginPopup } from '@/Store/LoginPopup/lib/actions';

function* loadDataMyVote(params = {}) {
	try {
		const endpoint = `${API_MY_VOTE}?vote_id=${params.payload.vote_id}`;
		const headers = {
			...params.payload.headers,
			Authorization: params.payload.Authorization,
		};
		const method = 'GET';
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const data = yield response.json();

		if (data?.meta?.code === 200) {
			yield put(loadMyVoteSuccess(data));
		} else {
			if (data?.meta?.code === 498) {
				yield put(loadLoginPopup());
			}

			yield put(failureMyVote(data?.meta));
		}
	} catch (err) {
		yield put(failureMyVote('Error fetch my vote', err));
	}
}

export default function* rootSaga() {
	yield takeEvery(ActionsMyVote.GET_MY_VOTE, loadDataMyVote);
}
