import { HYDRATE } from 'next-redux-wrapper';
import { ActionsCategoryDetail } from './actions';

const initialState = { data: [], status: 'fetch_init', message: '' };

function storeCategoryDetail(state = initialState, action) {
	switch (action.type) {
		case HYDRATE:
			return { ...state, ...action.payload.storeCategoryDetail };
		case ActionsCategoryDetail.LOAD_GET_CATEGORY_DETAIL:
			return { ...state, data: initialState.data, status: 'fetch_loading' };
		case ActionsCategoryDetail.RES_GET_CATEGORY_DETAIL:
			return { ...state, data: action.data, status: 'fetch_result' };
		case ActionsCategoryDetail.ERR_GET_CATEGORY_DETAIL:
			return {
				...state,
				status: 'fetch_error',
				message: 'Error...',
			};
		default:
			return { ...state };
	}
}

export default storeCategoryDetail;
