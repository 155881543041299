import { HYDRATE } from 'next-redux-wrapper';
import { ActionsSearchGroups } from './actions';

const initialState = { data: [], status: 'fetch_init', message: '' };

function storeSearchGroups(state = initialState, action) {
	switch (action.type) {
		case HYDRATE:
			return { ...state, ...action.payload.storeSearchGroups };
		case ActionsSearchGroups.LOAD_GET_SEARCH_GROUPS:
			return { ...state, data: initialState.data, status: 'fetch_loading' };
		case ActionsSearchGroups.RES_GET_SEARCH_GROUPS:
			return { ...state, data: action.data, status: 'fetch_result' };
		case ActionsSearchGroups.ERR_GET_SEARCH_GROUPS:
			return {
				...state,
				data: action,
				status: 'fetch_error',
				message: 'Error....',
			};
		default:
			return { ...state };
	}
}

export default storeSearchGroups;
