export const ActionsSearchUsers = {
	GET_SEARCH_USERS: 'get_search_users',
	LOAD_GET_SEARCH_USERS: 'load_get_search_users',
	RES_GET_SEARCH_USERS: 'res_get_search_users',
	ERR_GET_SEARCH_USERS: 'err_get_search_users',
	HYDRATE: 'HYDRATE',
};

export const API_SEARCH_USERS = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/searchUsers`;

export function failureSearchUsers(err) {
	return {
		type: ActionsSearchUsers.ERR_GET_SEARCH_USERS,
		err,
	};
}

export function loadSearchUsers(params = {}) {
	return {
		type: ActionsSearchUsers.GET_SEARCH_USERS,
		...params,
	};
}

export function loadGetSearchUsers(params = {}) {
	return {
		type: ActionsSearchUsers.LOAD_GET_SEARCH_USERS,
		...params,
	};
}

export function loadSearchUsersSuccess(data) {
	return {
		type: ActionsSearchUsers.RES_GET_SEARCH_USERS,
		data,
	};
}
