import { put, takeEvery } from 'redux-saga/effects';
import {
	ActionLoginPopup,
	failureLoginPopup,
	loadLoginPopupSuccess,
} from './actions';

function* loadDataLoginPopup() {
	try {
		yield put(loadLoginPopupSuccess(true));
	} catch (err) {
		yield put(failureLoginPopup(err));
	}
}

export default function* rootSaga() {
	yield takeEvery(ActionLoginPopup.GET_LOGIN_POPUP, loadDataLoginPopup);
}
