export const ActionsCurrentBoba = {
	GET_CURRENT_BOBA: 'get_current_boba',
	LOAD_GET_CURRENT_BOBA: 'load_current_boba',
	RES_GET_CURRENT_BOBA: 'res_get_current_boba',
	ERR_GET_CURRENT_BOBA: 'err_get_current_boba',
	HYDRATE: 'HYDRATE',
};

export const API_CURRENT_BOBA = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getCurrentBoba`;

export function failureCurrentBoba(err) {
	return {
		type: ActionsCurrentBoba.ERR_GET_CURRENT_BOBA,
		err,
	};
}

export function loadCurrentBoba(params = {}) {
	return {
		type: ActionsCurrentBoba.GET_CURRENT_BOBA,
		...params,
	};
}

export function loadGetCurrentBoba() {
	return {
		type: ActionsCurrentBoba.LOAD_GET_CURRENT_BOBA,
	};
}

export function loadCurrentBobaSuccess(data) {
	return {
		type: ActionsCurrentBoba.RES_GET_CURRENT_BOBA,
		data,
	};
}
