export const ActionsSearchArticles = {
	GET_SEARCH_ARTICLES: 'get_search_articles',
	LOAD_GET_SEARCH_ARTICLES: 'load_get_search_articles',
	RES_GET_SEARCH_ARTICLES: 'res_get_search_articles',
	ERR_GET_SEARCH_ARTICLES: 'err_get_search_articles',
	HYDRATE: 'HYDRATE',
};

export const API_SEARCH_ARTICLES = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/searchArticles`;

export function failureSearchArticles(err) {
	return {
		type: ActionsSearchArticles.ERR_GET_SEARCH_ARTICLES,
		err,
	};
}

export function loadSearchArticles(params = {}) {
	return {
		type: ActionsSearchArticles.GET_SEARCH_ARTICLES,
		...params,
	};
}

export function loadGetSearchArticles(params = {}) {
	return {
		type: ActionsSearchArticles.LOAD_GET_SEARCH_ARTICLES,
		...params,
	};
}

export function loadSearchArticlesSuccess(data) {
	return {
		type: ActionsSearchArticles.RES_GET_SEARCH_ARTICLES,
		data,
	};
}
