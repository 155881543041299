import { call, put, takeEvery } from 'redux-saga/effects';
import {
	ActionsCategoryDetail,
	// API_CATEGORY_DETAIL,
	// loadGetCategoryDetail,
	failureCategoryDetail,
	loadCategoryDetailSuccess,
} from './actions';
import { loadLoginPopup } from '@/Store/LoginPopup/lib/actions';

function* loadDataCategoryDetail(params = {}) {
	try {
		// const endpoint = `${API_CATEGORY_DETAIL}?id=${params.payload.category_id}`;
		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/bobaCategoryDetail?id=${params.payload.category_id}`;
		const headers = {
			...params.payload.headers,
			Authorization: params.payload.Authorization,
		};
		const method = 'GET';
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const data = yield response.json();

		if (data?.meta?.code === 200) {
			yield put(loadCategoryDetailSuccess(data));
		} else {
			if (data?.meta?.code === 498) {
				yield put(loadLoginPopup());
			}

			yield put(failureCategoryDetail(data?.meta));
		}
	} catch (err) {
		yield put(failureCategoryDetail('Error fetch category detail ', err));
	}
}

export default function* rootSaga() {
	yield takeEvery(
		ActionsCategoryDetail.GET_CATEGORY_DETAIL,
		loadDataCategoryDetail,
	);
}
