import { call, put, takeEvery } from '@redux-saga/core/effects';
import {
	ActionsSearchGroups,
	API_SEARCH_GROUPS,
	loadGetSearchGroups,
	failureSearchGroups,
	loadSearchGroupsSuccess,
} from './actions';
import { loadLoginPopup } from '@/Store/LoginPopup/lib/actions';

function* loadDataSearchGroups(params = {}) {
	yield put(loadGetSearchGroups());
	try {
		const endpoint = `${API_SEARCH_GROUPS}?search=${params.payload.search}`;
		const headers = { ...params.payload.headers };
		const method = 'GET';
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const data = yield response.json();

		if (data?.meta?.code === 200) {
			const groupsData =
				typeof data.payload.data !== 'undefined' ? data.payload.data : [];

			yield put(loadSearchGroupsSuccess(groupsData));
		} else {
			if (data?.meta?.code === 498) {
				yield put(loadLoginPopup());
			}

			yield put(failureSearchGroups(data?.meta));
		}
	} catch (err) {
		yield put(failureSearchGroups(err));
	}
}

export default function* rootSaga() {
	yield takeEvery(ActionsSearchGroups.GET_SEARCH_GROUPS, loadDataSearchGroups);
}
