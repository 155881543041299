import { HYDRATE } from 'next-redux-wrapper';
import { ActionsCompleting } from './actions';

const initialState = { data: {}, status: 'fetch_init', message: '' };

function storeCompleting(state = initialState, action) {
	switch (action.type) {
		case HYDRATE:
			return { ...state, ...action.payload.storeCompleting };
		case ActionsCompleting.LOAD_GET_COMPLETING:
			return { ...state, data: initialState.data, status: 'fetch_loading' };
		case ActionsCompleting.RES_GET_COMPLETING:
			return { ...state, data: action.data, status: 'fetch_result' };
		case ActionsCompleting.ERR_GET_COMPLETING:
			return {
				...state,
				status: 'fetch_error',
				message: 'Error...',
			};
		default:
			return { ...state };
	}
}

export default storeCompleting;
