export const ActionsCompleting = {
	GET_COMPLETING: 'get_completing',
	LOAD_GET_COMPLETING: 'load_completing',
	RES_GET_COMPLETING: 'res_get_completing',
	ERR_GET_COMPLETING: 'err_get_completing',
	HYDRATE: 'HYDRATE',
};

export const API_COMPLETING = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/getCompleting`;

export function failureCompleting(err) {
	return {
		type: ActionsCompleting.ERR_GET_COMPLETING,
		err,
	};
}

export function loadCompleting(params = {}) {
	return {
		type: ActionsCompleting.GET_COMPLETING,
		...params,
	};
}

export function loadCompletingSuccess(data) {
	return {
		type: ActionsCompleting.RES_GET_COMPLETING,
		data,
	};
}
