import { call, put, takeEvery } from 'redux-saga/effects';
import {
	ActionsCurrentBoba,
	API_CURRENT_BOBA,
	failureCurrentBoba,
	loadCurrentBobaSuccess,
} from './actions';
import { loadLoginPopup } from '@/Store/LoginPopup/lib/actions';

function* loadDataCurrentBoba(params = {}) {
	try {
		const endpoint = API_CURRENT_BOBA;
		const headers = { ...params.payload.headers };
		const method = 'GET';
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const data = yield response.json();

		if (data?.meta?.code === 200) {
			yield put(loadCurrentBobaSuccess(data));
		} else {
			if (data?.meta?.code === 498) {
				yield put(loadLoginPopup());
			}

			yield put(failureCurrentBoba(data?.meta));
		}
	} catch (err) {
		yield put(failureCurrentBoba('Error fetch current boba ', err));
	}
}

export default function* rootSaga() {
	yield takeEvery(ActionsCurrentBoba.GET_CURRENT_BOBA, loadDataCurrentBoba);
}
