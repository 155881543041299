import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionsWinners, failureWinners, loadWinnersSuccess } from './actions';
import { loadLoginPopup } from '@/Store/LoginPopup/lib/actions';

function* loadDataWinners(params = {}) {
	try {
		const endpoint = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/winners?category_id=${params.payload.category_id}`;
		const headers = { ...params.payload.headers };
		const method = 'GET';
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const data = yield response.json();

		if (data?.meta?.code === 200) {
			yield put(loadWinnersSuccess(data));
		} else {
			yield put(failureWinners(data?.meta));
			if (data?.meta?.code === 498) {
				yield put(loadLoginPopup());
			}
		}
	} catch (err) {
		yield put(failureWinners('Error fetch winners ', err));
	}
}

export default function* rootSaga() {
	yield takeEvery(ActionsWinners.GET_WINNERS, loadDataWinners);
}
