import { call, put, takeEvery } from 'redux-saga/effects';
import {
	ActionsWallet,
	API_WALLET,
	failureWallet,
	loadWalletSuccess,
	loadGetWallet,
} from './actions';
import { loadLoginPopup } from '@/Store/LoginPopup/lib/actions';

function* loadDataWallet(params = {}) {
	yield put(loadGetWallet({}));
	try {
		const endpoint = API_WALLET;
		const { Authorization } = params.payload;
		const headers = {
			...params.payload.headers,
			...(Authorization && { Authorization }),
		};
		const method = 'GET';
		const options = { headers, method };
		const response = yield call(fetch, endpoint, options);
		const data = yield response.json();

		if (data?.meta?.code === 200) {
			yield put(loadWalletSuccess(data));
		} else {
			if (data?.meta?.code === 498) {
				yield put(loadLoginPopup());
			}

			yield put(failureWallet(data.meta?.msg));
		}
	} catch (err) {
		yield put(failureWallet(err));
	}
}

export default function* rootSaga() {
	yield takeEvery(ActionsWallet.GET_WALLET, loadDataWallet);
}
