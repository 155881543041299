export const ActionsSearchGroups = {
	GET_SEARCH_GROUPS: 'get_search_groups',
	LOAD_GET_SEARCH_GROUPS: 'load_get_search_groups',
	RES_GET_SEARCH_GROUPS: 'res_get_search_groups',
	ERR_GET_SEARCH_GROUPS: 'err_get_search_groups',
	HYDRATE: 'HYDRATE',
};

export const API_SEARCH_GROUPS = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/searchGroups`;

export function failureSearchGroups(err) {
	return {
		type: ActionsSearchGroups.ERR_GET_SEARCH_GROUPS,
		err,
	};
}

export function loadSearchGroups(params = {}) {
	return {
		type: ActionsSearchGroups.GET_SEARCH_GROUPS,
		...params,
	};
}

export function loadGetSearchGroups(params = {}) {
	return {
		type: ActionsSearchGroups.LOAD_GET_SEARCH_GROUPS,
		...params,
	};
}

export function loadSearchGroupsSuccess(data) {
	return {
		type: ActionsSearchGroups.RES_GET_SEARCH_GROUPS,
		data,
	};
}
