export const ActionsCategoryDetail = {
	GET_CATEGORY_DETAIL: 'get_category_detail',
	LOAD_GET_CATEGORY_DETAIL: 'load_get_category_detail',
	RES_GET_CATEGORY_DETAIL: 'res_get_category_detail',
	ERR_GET_CATEGORY_DETAIL: 'err_get_category_detail',
	HYDRATE: 'HYDRATE',
};

export const API_CATEGORY_DETAIL = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/bobaCategoryDetail`;

export function failureCategoryDetail(err) {
	return {
		type: ActionsCategoryDetail.ERR_GET_CATEGORY_DETAIL,
		err,
	};
}

export function loadCategoryDetail(params = {}) {
	return {
		type: ActionsCategoryDetail.GET_CATEGORY_DETAIL,
		...params,
	};
}

export function loadGetCategoryDetail(params = {}) {
	return {
		type: ActionsCategoryDetail.LOAD_GET_CATEGORY_DETAIL,
		...params,
	};
}

export function loadCategoryDetailSuccess(data) {
	return {
		type: ActionsCategoryDetail.RES_GET_CATEGORY_DETAIL,
		...data,
	};
}
