import React from 'react';

function offline() {
	return (
		<div id="popup" className="wrapper-popup">
			<div className="popup-content">You&apos;re Offline</div>

			<style jsx>
				{`
					.wrapper-popup {
						position: fixed;
						top: 3rem;
						right: 1rem;
						height: 50px;
						background-color: grey;
						text-align: center;
						z-index: 99;
						width: 200px;
						justify-content: center;
						align-items: center;
						display: flex;
						flex-direction: column;
						color: #fff;
						transition: background-color 0.3s ease;
						animation: blink 1s infinite;
						border-radius: 10px;
					}

					@keyframes blink {
						0%,
						100% {
							opacity: 1;
						}
						50% {
							opacity: 0;
						}
					}
					@media (max-width: 767px) {
						.wrapper-popup {
							top: 10%;
							right: 5%;
							font-size: 12px;
							width: 100px;
							height: 25px;
							border-radius: 5px;
						}
					}
				`}
			</style>
		</div>
	);
}

export default offline;
