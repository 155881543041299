/**
 * Predefine search_users
 * @param {*} search_users
 */

const predefinedSearchUser = (params = {}) => {
	if (params.username) {
		return {
			email: params.email,
			follower_count: params.follower_count,
			following_count: params.following_count,
			fullname: params.fullname,
			is_verified: params.is_verified,
			total_point: params.total_point,
			total_post: params.total_post,
			total_review: params.total_review,
			username: params.username,
			usrapo_id: params.usrapo_id,
			usrpic: params.usrpic,
			user_url: `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/user/${params.username}`,
		};
	} else {
		return null;
	}
};

const predefinedSearchUsers = (params = []) =>
	params.map((item) => predefinedSearchUser(item));

export const predefinedSearchUsersModel = (params = {}) => {
	// predefine search_users
	const searchUsers = predefinedSearchUsers(params);
	const res = { data: searchUsers };

	return res;
};
