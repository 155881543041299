import { call, put, takeEvery } from 'redux-saga/effects';
import {
	ActionsPoint,
	API_POINT,
	failurePoint,
	loadPointSuccess,
	loadGetPoint,
} from './actions';
import { loadLoginPopup } from '@/Store/LoginPopup/lib/actions';

function* loadDataPoint(params = {}) {
	yield put(loadGetPoint({}));
	try {
		const endpoint = API_POINT;
		const { Authorization } = params.payload;
		const headers = {
			...params.payload.headers,
			...(Authorization && { Authorization }),
		};
		const method = 'GET';
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const data = yield response.json();

		if (data?.meta.code === 200) {
			if (data.meta.code === 200) {
				yield put(loadPointSuccess(data));
			} else {
				if (data?.meta?.code === 498) {
					yield put(loadLoginPopup);
				}

				yield put(failurePoint(data.meta?.msg));
			}
		}
	} catch (err) {
		yield put(failurePoint(err));
	}
}

export default function* rootSaga() {
	yield takeEvery(ActionsPoint.GET_POINT, loadDataPoint);
}
