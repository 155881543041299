import { combineReducers } from 'redux';
import { storeSelf } from '@fdn/profile_store';
import storeCompleting from '../completing/lib/store';
import storeSearchArticles from '../search_articles/lib/store';
import storeSearchBrands from '../search_brands/lib/store';
import storeSearchGroups from '../search_groups/lib/store';
import storeSearchProducts from '../search_products/lib/store';
import storeSearchUsers from '../search_users/lib/store';
import storeArticleTagSlug from '../article_slug/lib/store';
import storePredefined from '../predefined/lib/store';
import storeCurrentBoba from '../current_boba/lib/store';
import storeBobaList from '../boba_list/lib/store';
import storeCategory from '../category/lib/store';
import storeMyVote from '../my_vote/lib/store';
import storeWinners from '../winners/lib/store';
import storeCategoryDetail from '../category_detail/lib/store';
import storeWallet from '../AlloWallet/lib/store';
import storePoint from '../AlloPoint/lib/store';
import storeLoginPopup from '../LoginPopup/lib/store';

const rootReducer = combineReducers({
	storeSelf,
	storeCompleting,
	storeSearchArticles,
	storeSearchBrands,
	storeSearchGroups,
	storeSearchProducts,
	storeSearchUsers,
	storeArticleTagSlug,
	storePredefined,
	storeCurrentBoba,
	storeBobaList,
	storeCategory,
	storeMyVote,
	storeWinners,
	storeCategoryDetail,
	storeWallet,
	storePoint,
	storeLoginPopup,
});

export default rootReducer;
