import { call, put, takeEvery } from '@redux-saga/core/effects';
import {
	ActionsSearchProducts,
	API_SEARCH_PRODUCTS,
	failureSearchProducts,
	loadSearchProducts,
	loadSearchProductsSuccess,
} from './actions';
import { loadLoginPopup } from '@/Store/LoginPopup/lib/actions';

function* loadDataSearchProducts(params = {}) {
	yield put(loadSearchProducts());
	try {
		const endpoint = `${API_SEARCH_PRODUCTS}?search=${params.payload.search}`;
		const headers = { ...params.payload.headers };
		const method = 'GET';
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const data = yield response.json();

		if (response.status >= 200 && response.status < 300) {
			const productsData = [];
			data.data.map((item) => {
				productsData.push({
					id: item.id,
					item_id: item.item_id ? item.item_id : '',
					search_id: item.search_id ? item.search_id : '',
					name: item.prod_item,
					slug: item.prod_slug,
					date: item.created_at,
					image: item.prod_image,
					is_review: item.already_review,
					brand: {
						name: item.brand_name,
						slug: item.brand_slug,
					},
					shade: item.shade_item,
					category: {
						name: item.category.name,
						slug: item.category.slug,
						parent: {
							name: item.category.parent_category.name,
							slug: item.category.parent_category.slug,
						},
					},
					rating: {
						average: item.rating_avg,
						user: 0,
						total: item.review_num,
					},
				});

				return productsData;
			});

			yield put(loadSearchProductsSuccess(productsData));
		} else {
			yield put(failureSearchProducts(data?.meta));
			if (data?.meta?.code === 498) {
				yield put(loadLoginPopup());
			}
		}
	} catch (err) {
		yield put(failureSearchProducts(err));
	}
}

export default function* rootSaga() {
	yield takeEvery(
		ActionsSearchProducts.GET_SEARCH_PRODUCTS,
		loadDataSearchProducts,
	);
}
