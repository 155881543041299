import { HYDRATE } from 'next-redux-wrapper';
import { ActionsMyVote } from './actions';

const initialState = { data: {}, status: 'fetch_init', message: '' };

function storeMyVote(state = initialState, action) {
	switch (action.type) {
		case HYDRATE:
			return { ...state, ...action.payload.storeMyVote };
		case ActionsMyVote.LOAD_GET_MY_VOTE:
			return { ...state, data: initialState.data, status: 'fetch_loading' };
		case ActionsMyVote.RES_GET_MY_VOTE:
			return { ...state, data: action.data, status: 'fetch_result' };
		case ActionsMyVote.ERR_GET_MY_VOTE:
			return {
				...state,
				status: 'fetch_error',
				message: 'Error...',
			};
		default:
			return { ...state };
	}
}

export default storeMyVote;
