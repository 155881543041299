export const ActionsSearchProducts = {
	GET_SEARCH_PRODUCTS: 'get_search_products',
	LOAD_GET_SEARCH_PRODUCTS: 'load_get_search_products',
	RES_GET_SEARCH_PRODUCTS: 'res_get_search_products',
	ERR_GET_SEARCH_PRODUCTS: 'err_get_search_products',
	HYDRATE: 'HYDRATE',
};

export const API_SEARCH_PRODUCTS = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/searchProducts`;

export function failureSearchProducts(err) {
	return {
		type: ActionsSearchProducts.ERR_GET_SEARCH_PRODUCTS,
		err,
	};
}

export function loadSearchProducts(params = {}) {
	return {
		type: ActionsSearchProducts.GET_SEARCH_PRODUCTS,
		...params,
	};
}

export function loadGetSearchProducts(params = {}) {
	return {
		type: ActionsSearchProducts.LOAD_GET_SEARCH_PRODUCTS,
		...params,
	};
}

export function loadSearchProductsSuccess(data) {
	return {
		type: ActionsSearchProducts.RES_GET_SEARCH_PRODUCTS,
		data,
	};
}
