import { call, put, takeEvery } from 'redux-saga/effects';
import {
	ActionsPredefined,
	API_PREDEFINED,
	failurePredefined,
	loadPredefinedSuccess,
} from './actions';
import { loadLoginPopup } from '@/Store/LoginPopup/lib/actions';

function* loadDataPredefined(params = {}) {
	try {
		const endpoint = API_PREDEFINED;
		const headers = { ...params.payload.headers };
		const method = 'GET';
		const options = { headers, method };

		const response = yield call(fetch, endpoint, options);
		const data = yield response.json();

		if (data?.meta?.code) {
			yield put(loadPredefinedSuccess(data.data));
		} else {
			if (data?.meta?.code === 498) {
				yield put(loadLoginPopup());
			}

			throw failurePredefined(data?.meta);
		}
	} catch (err) {
		yield put(failurePredefined(err));
	}
}

export default function* rootSaga() {
	yield takeEvery(ActionsPredefined.GET_PREDEFINED, loadDataPredefined);
}
