export const ActionLoginPopup = {
	GET_LOGIN_POPUP: 'get_login_popup',
	LOAD_GET_LOGIN_POPUP: 'load_login_popup',
	RES_GET_LOGIN_POPUP: 'res_get_login_popup',
	ERR_GET_LOGIN_POPUP: 'err_get_login_popup',
	HYDRATE: 'HYDRATE',
};

export function failureLoginPopup(err) {
	return {
		type: ActionLoginPopup.ERR_GET_LOGIN_POPUP,
		err,
	};
}

export function loadLoginPopup() {
	return {
		type: ActionLoginPopup.GET_LOGIN_POPUP,
	};
}

export function loadLoginPopupSuccess(data) {
	return {
		type: ActionLoginPopup.RES_GET_LOGIN_POPUP,
		data,
	};
}
