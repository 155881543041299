export const ActionsMyVote = {
	GET_MY_VOTE: 'get_my_vote',
	LOAD_GET_MY_VOTE: 'load_get_my_vote',
	RES_GET_MY_VOTE: 'res_get_my_vote',
	ERR_GET_MY_VOTE: 'err_get_my_vote',
	HYDRATE: 'HYDRATE',
};

export const API_MY_VOTE = `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/myVote`;

export function failureMyVote(err) {
	return {
		type: ActionsMyVote.ERR_GET_MY_VOTE,
		err,
	};
}

export function loadMyVote(params = {}) {
	return {
		type: ActionsMyVote.GET_MY_VOTE,
		...params,
	};
}

export function loadGetMyVote() {
	return {
		type: ActionsMyVote.LOAD_GET_MY_VOTE,
	};
}

export function loadMyVoteSuccess(data) {
	return {
		type: ActionsMyVote.RES_GET_MY_VOTE,
		data,
	};
}
