import { fork } from 'redux-saga/effects';
import { dispatchSelf } from '@fdn/profile_store';
import dispatchCompleting from '../completing/lib/fetch';
import dispatchSearchArticles from '../search_articles/lib/fetch';
import dispatchSearchBrands from '../search_brands/lib/fetch';
import dispatchSearchGroups from '../search_groups/lib/fetch';
import dispatchSearchProducts from '../search_products/lib/fetch';
import dispatchSearchUsers from '../search_users/lib/fetch';
import dispatchArticleBySlug from '../article_slug/lib/fetch';
import dispatchPredefined from '../predefined/lib/fetch';
import dispatchCurrentBoba from '../current_boba/lib/fetch';
import dispatchBobaList from '../boba_list/lib/fetch';
import dispatchCategory from '../category/lib/fetch';
import dispatchMyVote from '../my_vote/lib/fetch';
import dispatchWinners from '../winners/lib/fetch';
import dispatchCategoryDetail from '../category_detail/lib/fetch';
import dispatchWallet from '../AlloWallet/lib/fetch';
import dispatchPoint from '../AlloPoint/lib/fetch';
import dispatchLoginPopup from '../LoginPopup/lib/fetch';

export default function* rootSaga() {
	yield fork(dispatchSelf);
	yield fork(dispatchCompleting);
	yield fork(dispatchSearchArticles);
	yield fork(dispatchSearchBrands);
	yield fork(dispatchSearchGroups);
	yield fork(dispatchSearchProducts);
	yield fork(dispatchSearchUsers);
	yield fork(dispatchArticleBySlug);
	yield fork(dispatchPredefined);
	yield fork(dispatchCurrentBoba);
	yield fork(dispatchBobaList);
	yield fork(dispatchCategory);
	yield fork(dispatchMyVote);
	yield fork(dispatchWinners);
	yield fork(dispatchCategoryDetail);
	yield fork(dispatchWallet);
	yield fork(dispatchPoint);
	yield fork(dispatchLoginPopup);
}
