/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import Offline from 'Components/modal/offline';
import Cookies from 'js-cookie';
import { wrapper } from '../store';

import 'Styles/globals.css';

function App({ Component, pageProps, err }) {
	const [isOnline, setIsOnline] = useState(true);

	const usrnameSsr = pageProps?.getStoreSelf?.data?.username || '';
	const cookiesUsername = Cookies.get('usrname') || '';

	useEffect(() => {
		// Set initial status
		setIsOnline(navigator.onLine);

		// Handler when the network status changes
		function updateOnlineStatus() {
			setIsOnline(navigator.onLine);
		}

		// Listen to online and offline events
		window.addEventListener('online', updateOnlineStatus);
		window.addEventListener('offline', updateOnlineStatus);

		// Clean up the event listeners when the component unmounts
		return () => {
			window.removeEventListener('online', updateOnlineStatus);
			window.removeEventListener('offline', updateOnlineStatus);
		};
	}, []);

	useEffect(async () => {
		/**
		 * resgister this service worker in app because of
		 * want to access to the full website - all asset, page etc
		 */
		if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
			navigator.serviceWorker
				.register('/sw.js', { scope: '/' })
				.then((registration) => {
					if (registration.installing) {
						console.log('Service worker is installing.');
					} else if (registration.waiting) {
						console.log('Service worker is installed and waiting.');
					} else if (registration.active) {
						if (usrnameSsr !== cookiesUsername) {
							caches.keys().then((cacheNames) =>
								Promise.all(
									cacheNames.map((cacheName) => {
										if (cacheName === 'precache-static') {
											window.location.reload();
											return caches.delete(cacheName);
										} else {
											return Promise.resolve();
										}
									}),
								),
							);
						}

						console.log('Service worker is active.', registration.scope);
						registration.active.postMessage({
							env: process.env.NEXT_PUBLIC_NODE_ENV,
							urlSso: process.env.NEXT_PUBLIC_SSO_URL,
						});
					}

					registration.update();
				})
				.catch((error) => {
					console.error('Service Worker registration failed:', error);
				});
		} else {
			console.error('Service Workers not supported');
		}

		// see much storage we're using
		if ('storage' in navigator) {
			if ('estimate' in navigator.storage) {
				// get total storage and current usage
				// see if storage cant be set perssistent or stay best-effor
				navigator.storage.estimate().then((storage) => {
					const useKb = parseInt(storage.usage, 1 / 1024);
					const quoteKb = parseInt(storage.quota, 1 / 1024);

					console.log(`using sotrage info: ${useKb} KB of ${quoteKb} Kb`);
				});

				// see the browse can be set perssistent or stay best-eff
			} else {
				console.log('No support storageManager method');
			}
		}
	}, []);

	return (
		<>
			{isOnline ? '' : <Offline />}
			<Component {...pageProps} err={err} />
		</>
	);
}

App.propTypes = {
	Component: PropTypes.func.isRequired,
	pageProps: PropTypes.any.isRequired,
	err: PropTypes.any,
};

App.defaultProps = {
	err: {},
};

// export default App
export default wrapper.withRedux(App);
