import { HYDRATE } from 'next-redux-wrapper';
import { ActionsPoint } from './actions';
// import { predefined_completing_model } from "../helper/predefined_completing"

const initialState = { data: {}, status: 'fetch_init', message: '' };

function storePoint(state = initialState, action) {
	switch (action.type) {
		case HYDRATE:
			return { ...state, ...action.payload.storePoint };
		case ActionsPoint.LOAD_GET_POINT:
			return { ...state, data: action.data, status: 'fetch_loading' };
		case ActionsPoint.RES_GET_POINT:
			return { ...state, data: action.data, status: 'fetch_result' };
		case ActionsPoint.ERR_GET_POINT:
			return {
				...state,
				status: 'fetch_error',
				message: 'Error...',
			};
		default:
			return { ...state };
	}
}

export default storePoint;
